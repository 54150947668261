// import React from "react"
// import { Grid, Typography } from "@material-ui/core"
// import { data } from "../data/partners"
// import { PartnerCard } from "../components/cards"

// export default function PartnerRelations() {
//   const [state] = React.useState({
//     dataValues: data,
//   });

//   return (
//     <div className="partnerRelationsContainer">
//       <Typography className="sectionTitle">Partners</Typography>
//       <div className="spacer" />
//       <Grid container xs={12} sm={10} md={8} lg={12} spacing={3} className="container">
//         {state.dataValues.map((item) => {
//           return (
//             <Grid item xs={12} sm={12} md={6} lg={4} className="columns">
//               <PartnerCard
//                 title={item.title}
//                 link={item.link}
//                 image={item.image}
//                 desc={item.service}
//               />
//             </Grid>
//           );
//         })}
//       </Grid>
//     </div>
//   );
// }

import React from "react"
import { 
   Grid,
   Typography
   } from "@material-ui/core"
   import { PartnerCard } from "../components/cards"
import {data} from "../data/partners.js"


export default function InvestmentsContent() {
  const [state] = React.useState({
    dataValues: data,
  });

  return (
    <div className="partnersContainer" >
      <Grid
        container
        xs={12}
        sm={11}
        md={11}
        lg={11}
        spacing={3}
        className="marginCenter"
      >
         <Grid item xs={12}>
          <Typography variant="h5" >
          Endpoint Partners
          </Typography>
          <div className="spacer" />
         </Grid>
        {state.dataValues.map((data) => {
          if(data.category === "Endpoint"){

          
            return (
                <Grid item xs={12} sm={6} md={4} lg={4}>

                <PartnerCard
                  title={data.title}
                  link={data.link}
                  image={data.image}
                  desc={data.service}
                />

                </Grid>
          
            )
          }
        })}
        <div className="spacer" />
          <div className="dividerShape" />
        <div className="spacer" />

        <Grid item xs={12}>
          <Typography variant="h5" >
            Network Partners
          </Typography>
          <div className="spacer" />
         </Grid>
        {state.dataValues.map((data) => {
          if(data.category === "Network"){

          
            return (
                <Grid item xs={12} sm={6} md={4} lg={4}>

                 
                <PartnerCard
                  title={data.title}
                  link={data.link}
                  image={data.image}
                  desc={data.service}
                />

                </Grid>
          
            )
          }
        })}

        <div className="spacer" />
         <div className="dividerShape" />
        <div className="spacer" />

      <Grid item xs={12}>
          <Typography variant="h5" >
            Identity Partners
          </Typography>
          <div className="spacer" />
         </Grid>
        {state.dataValues.map((data) => {
          if(data.category === "Identity"){

          
            return (
                <Grid item xs={12} sm={6} md={4} lg={4}>

                 
                <PartnerCard
                  title={data.title}
                  link={data.link}
                  image={data.image}
                  desc={data.service}
                />

                </Grid>
          
            )
          }
        })}

      <div className="spacer" />
         <div className="dividerShape" />
        <div className="spacer" />

      <Grid item xs={12}>
          <Typography variant="h5" >
            Cloud Partners
          </Typography>
          <div className="spacer" />
         </Grid>
        {state.dataValues.map((data) => {
          if(data.category === "Cloud"){

          
            return (
                <Grid item xs={12} sm={6} md={4} lg={4}>

                 
                <PartnerCard
                  title={data.title}
                  link={data.link}
                  image={data.image}
                  desc={data.service}
                />

                </Grid>
          
            )
          }
        })}

        <div className="spacer" />
         <div className="dividerShape" />
        <div className="spacer" />

      <Grid item xs={12}>
          <Typography variant="h5" >
            Cyber Partners
          </Typography>
          <div className="spacer" />
         </Grid>
        {state.dataValues.map((data) => {
          if(data.category === "Cyber"){

          
            return (
                <Grid item xs={12} sm={6} md={4} lg={4}>

                 
                <PartnerCard
                  title={data.title}
                  link={data.link}
                  image={data.image}
                  desc={data.service}
                />

                </Grid>
          
            )
          }
        })}

    <div className="spacer" />
         <div className="dividerShape" />
        <div className="spacer" />

      <Grid item xs={12}>
          <Typography variant="h5" >
            Oberational Systems Partners
          </Typography>
          <div className="spacer" />
         </Grid>
        {state.dataValues.map((data) => {
          if(data.category === "Operational"){

          
            return (
                <Grid item xs={12} sm={6} md={4} lg={4}>

                 
                <PartnerCard
                  title={data.title}
                  link={data.link}
                  image={data.image}
                  desc={data.service}
                />

                </Grid>
          
            )
          }
        })}

<div className="spacer" />
         <div className="dividerShape" />
        <div className="spacer" />

      <Grid item xs={12}>
          <Typography variant="h5" >
            Compliance Partners
          </Typography>
          <div className="spacer" />
         </Grid>
        {state.dataValues.map((data) => {
          if(data.category === "Compliance"){

          
            return (
                <Grid item xs={12} sm={6} md={4} lg={4}>

                <PartnerCard
                  title={data.title}
                  link={data.link}
                  image={data.image}
                  desc={data.service}
                />

                </Grid>
          
            )
          }
        })}


        <div className="spacer" />
         <div className="dividerShape" />
        <div className="spacer" />


      </Grid>
    </div>
  );
}
