import React from "react"
import { Grid, Typography, Paper } from "@material-ui/core"
import { ProfileCard } from "../components/cards"
import AboutUsJson from "../data/aboutUs.json"
import { data } from "../data/profileInfo"

export default function AboutUsContent() {
  const [state] = React.useState({
    abousUsData: AboutUsJson,
    profileInfoData: data,
  });

  return (
    <div className="aboutUsPageContainer">
      <Grid container xs={12} spacing="3">
 

        <Grid container xs={12}>
      
          <Grid item sm={12}>
            <div className="spacer" />
            <Typography className="pageSubTitleSection">
              Meet our Team
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          xs={11}
          sm={11}
          md={12}
          lg={12}
          spacing={3}
          className="profileContainer"
        >
        
          {state.profileInfoData.map((item) => {
            return (
              <Grid item xs={12} sm={12} md={6} lg={6} className="profileInfo" style={{background: '#f0f7fb', borderRadius: '20px', padding: '15px', border: '10px solid #fff'}} >
                  <ProfileCard
                    name={item.name + " profile image"}
                    source={item.image}
                  />
                  <div className="spacer" />
                  <Typography className="name">{item.name}</Typography>
                  <Typography className="title">{item.title}</Typography>
                  <Typography className="email">{item.email}</Typography>
                  <div className="spacer" />
                  <Typography variant="body1" className="desc">
                    {item.description}
                  </Typography>
                
                <div className="spacer" />
                <div className="spacer" />
             
               </Grid>
            ) 
          })}
         
         
        </Grid>

        <div className="spacer" />
        <div className="spacer" />
      </Grid>
    </div>
  );
}
