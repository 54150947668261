import React from "react"
import { 
    Typography,
    Grid,
    ButtonGroup,
    Button
  } from "@material-ui/core"
import { RegCard } from "../components/cards"
import cyber from "../assets/images/CYBER_HOME_BANNER.jpg"

export default function AboutAI() {

  return (
    <div className="aboutAIContainer">
      <Grid container xs={12} className="banner">
        <Grid item xs={12} sm={12}>
          <Typography className="aboutTitle fadePanel">
            SAIC &nbsp;&nbsp;
            <b>
              <i className="line">Cyber Security</i>{" "}
            </b>{" "}
          </Typography>

          <img src={cyber} alt="Home Banner" style={{ width: "100%" }} />
        </Grid>
       
      </Grid>

      <Grid container xs={12} sm={12} md={12} lg={12} className="homeButtons"  >
        <Grid item xs={12}  sm={4} md={4} lg={3} className="marginCenter" >

            <ButtonGroup variant="contained">
              <Button 
                target="_blank"
                href="https://issaic.saic.com/sites/digital/_layouts/15/WopiFrame.aspx?sourcedoc=%7B6C5E198E-32EF-4286-BE43-0110A8A51783%7D&file=Trust%20Resilience%20Offering%20Guide.docx&action=default"
                variant="contained"
              >
                Offering Guide 
                </Button>
            
              <Button
                target="_blank"
                href="https://issaic.saic.com/sites/digital/Cyber%20Documents/101%20Business%20Documents/Trust%20Resilience%20The%20SAIC%20Cyber%20Offering.pptx?d=w9a0e7c3f3996487580f24af987a71893"
                variant="contained"
              >
                Customer Brief
              </Button>
            </ButtonGroup>
             
          </Grid>
      </Grid>

    </div>
  )
}
