import Crowdstrike from "../assets/images/partnersIcons/Crowdstrike.png"
import Dragos from "../assets/images/partnersIcons/Dragos.png"
import Mandiant from "../assets/images/partnersIcons/Mandiant.png"
import Orca from "../assets/images/partnersIcons/Orca.png"
import Qmulos from "../assets/images/partnersIcons/Qmulos.png"
import Splunk from "../assets/images/partnersIcons/Splunk.png"
import Stealth from "../assets/images/partnersIcons/Stealth.png"
import Zscaler from "../assets/images/partnersIcons/Zscaler.png"
import Tanium from "../assets/images/partnersIcons/Tanium.jpg"
import Tenable from "../assets/images/partnersIcons/Tenable.png"
import Vectra from "../assets/images/partnersIcons/Vectra.png"
import Cisco from "../assets/images/partnersIcons/Cisco.jpg"
import Microsoft from "../assets/images/partnersIcons/Microsoft.png"
import CyberArk from "../assets/images/partnersIcons/Cyberark.png"
import Sailpoint from "../assets/images/partnersIcons/SailPoint.png"
import ForgeRock from "../assets/images/partnersIcons/ForgeRock.png"
import Ping from "../assets/images/partnersIcons/PingIdentity.png"
import Strata from "../assets/images/partnersIcons/Strata.png"
import Okta from "../assets/images/partnersIcons/Okta.png"
import PaloAlto from "../assets/images/partnersIcons/PaloAlto.jpg"
import Securonix from "../assets/images/partnersIcons/Securonix.png"
import Shift5 from "../assets/images/partnersIcons/Shift5.png"

export const data = [
  {
    category:"Endpoint",
    title: "Crowdstrike",
    service: "Real-time, AI-based endpoint security, cloud security, threat intelligence, identity protection, forensics, and hygiene. Implemented by SAIC at Texas DIR.",
    image: Crowdstrike,
    link: "https://www.crowdstrike.com/",
  },
  {
    category: "Operational",
    title: "Dragos",
    service: "Leader in OT Incident and Vulnerability Management",
    image: Dragos,
    link: "https://www.dragos.com",
  },
  {
    category: "Compliance",
    title: "Mandiant",
    service: "Cyber defense solutions provider powered by industry-leading expertise, intelligence and innovative technology.",
    image: Mandiant,
    link: "https://www.mandiant.com/",
  },
  {
    category: "Cloud",
    title: "Orca",
    service: "Cloud Native Application Portfolio Protection",
    image: Orca,
    link: "https://orca.security",
  },
  {
    category: "Compliance",
    title: "Qmulos",
    service: "Standard compliance reporting tool ",
    image: Qmulos,
    link: "https://www.qmulos.com/",
  },
  {
    category: "Cyber",
    title: "Splunk",
    service: "Security Incident and Event Management (SIEM), Security Orchestration and Response.  Implemented by SAIC at CENTCOM, Orange County, Texas DIR. ",
    image: Splunk,
    link: "https://splunk.com",
  },
  {
    category: "Network",
    title: "Unisys Stealth",
    service: "Provides Zero Trust Architecures, full data encryption (Data in motion and at rest), asset cloaking and microsegmentation.",
    image: Stealth,
    link: "https://stealthsecurity.unisys.com/",
  },
  {
    category: "Network",
    title: "Zscaler",
    service: "TIC 3.0 compliant remote access to SaaS, cloud, and premise-based resources.  Used by FCC and Dept. of State.",
    image: Zscaler,
    link: "https://www.zscaler.com/",
  },
  {
    category: "Endpoint",
    title: "Tanium",
    service: "TIC 3.0 compliant remote access to SaaS, cloud, and premise-based resources.  Used by FCC and Dept. of State.",
    image: Tanium,
    link: "https://www.tanium.com/",
  },
  {
    category: "Endpoint",
    title: "Tenable",
    service: "Widely deployed at GSA and other SAIC customers.",
    image: Tenable,
    link: "https://www.tenable.com/",
  },
  {
    category: "Network",
    title: "Vectra",
    service: "Proactive identification of threats and vulnerabilities mapped to MITRE ATT&CK vectors through analytics of network traffic.  Used by DoD and DI.",
    image: Vectra,
    link: "https://www.vectra.ai/",
  },
  {
    category: "Network",
    title: "Cisco",
    service: "Zero trust enabled campus, data center, Wi-Fi, and SD-WAN – incorporates identity and application attributes in network policy.  SAIC installed at GSA and NASA as well as CSfC designs.",
    image: Cisco,
    link: "https://www.cisco.com/",
  },
  {
    category: "Identity",
    title: "Microsoft",
    service: "Zero trust enabled campus, data center, Wi-Fi, and SD-WAN – incorporates identity and application attributes in network policy.  SAIC installed at GSA and NASA as well as CSfC designs.",
    image: Microsoft,
    link: "https://www.microsoft.com/",
  },
  {
    category: "Identity",
    title: "Cyberark",
    service: "Privileged Access Management  (PAM).  Standard at most Federal agencies.",
    image: CyberArk,
    link: "https://www.cyberark.com/",
  },
  {
    category: "Identity",
    title: "SailPoint",
    service: "Identity Governance.  Standard at most Federal agencies.",
    image: Sailpoint,
    link: "https://www.sailpoint.com/",
  },
  {
    category: "Identity",
    title: "ForgeRock",
    service: "Microservices-based Identity, Credential, and Access Management (ICAM).",
    image: ForgeRock,
    link: "https://www.forgerock.com/",
  },
  {
    category: "Identity",
    title: "Ping Identity",
    service: "Single-Sign-On, Identity Federation,  Master User Record (MUR).  Standard used by DoD.",
    image: Ping,
    link: "https://www.pingidentity.com",
  },
  {
    category: "Identity",
    title: "Strata",
    service: "Legacy Identity Integration.  Significant cost and labor savings when integrating legacy identity technologies.",
    image: Strata,
    link: "https://www.strata.io/",
  },
  {
    category: "Identity",
    title: "Okta",
    service: "SaaS-based Single-Sign-On. Standard at many Civilian Federal agencies.",
    image: Okta,
    link: "https://www.okta.com/",
  },
  {
    category: "Cloud",
    title: "Paloalto",
    service: "Cloud Native Application Portfolio Protection.  FedRAMP’d and in evalution at multiple agencies.",
    image: PaloAlto,
    link: "https://www.paloaltonetworks.com/",
  },
  {
    category: "Cyber",
    title: "Securonics",
    service: "Used by SAIC ITO.",
    image: Securonix,
    link: "https://www.ndm.net/securonix/",
  },
  {
    category: "Operational",
    title: "Shift 5",
    service: "Modern Cybersecurity for Planes, Trains,and Tanks.  Shift5 is the OT cybersecurity company that protects the world’s transportation infrastructure and weapons systems from cyberattacks.",
    image: Shift5,
    link: "https://www.shift5.io/",
  },




]
