// Localhost
const LOCAL_SIGN_IN = process.env.REACT_APP_LOCAL_SIGNIN;
const LOCAL_SIGN_OUT = process.env.REACT_APP_LOCAL_SIGNOUT;
const LOCAL_CLIENT_ID = process.env.REACT_APP_LOCAL_CLIENT_ID;
// Dev
const DEV_SIGN_IN = process.env.REACT_APP_DEV_SIGNIN;
const DEV_SIGN_OUT = process.env.REACT_APP_DEV_SIGNOUT;
const DEV_CLIENT_ID = process.env.REACT_APP_DEV_CLIENT_ID;
// Test
const TEST_SIGN_IN = process.env.REACT_APP_TEST_SIGNIN;
const TEST_SIGN_OUT = process.env.REACT_APP_TEST_SIGNOUT;
const TEST_CLIENT_ID = process.env.REACT_APP_TEST_CLIENT_ID;
// Prod
const PROD_SIGN_IN = process.env.REACT_APP_PROD_SIGNIN;
const PROD_SIGN_OUT = process.env.REACT_APP_PROD_SIGNOUT;
const PROD_CLIENT_ID = process.env.REACT_APP_PROD_CLIENT_ID;

const awsLocalConfig = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_73Q7wRH2O",
  aws_user_pools_web_client_id: LOCAL_CLIENT_ID,
  oauth: {
    domain: "auth.saicds.com",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: LOCAL_SIGN_IN,
    redirectSignOut: LOCAL_SIGN_OUT,
    responseType: "code",
  },
  federationTarget: "SAIC",
};

const awsDevConfig = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_73Q7wRH2O",
  aws_user_pools_web_client_id: DEV_CLIENT_ID,
  oauth: {
    domain: "auth.saicds.com",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: DEV_SIGN_IN,
    redirectSignOut: DEV_SIGN_OUT,
    responseType: "code",
  },
  federationTarget: "SAIC",
};

const awsTestConfig = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_73Q7wRH2O",
  aws_user_pools_web_client_id: TEST_CLIENT_ID,
  oauth: {
    domain: "auth.saicds.com",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: TEST_SIGN_IN,
    redirectSignOut: TEST_SIGN_OUT,
    responseType: "code",
  },
  federationTarget: "SAIC",
};

const awsProdConfig = {
  aws_project_region: "us-east-1",
  aws_cognito_region: "us-east-1",
  aws_user_pools_id: "us-east-1_73Q7wRH2O",
  aws_user_pools_web_client_id: PROD_CLIENT_ID,
  oauth: {
    domain: "auth.saicds.com",
    scope: ["email", "openid", "profile", "aws.cognito.signin.user.admin"],
    redirectSignIn: PROD_SIGN_IN,
    redirectSignOut: PROD_SIGN_OUT,
    responseType: "code",
  },
  federationTarget: "SAIC",
};

export default function AwsExportConfig() {
  const hostname =
    typeof window !== "undefined" && window.location.hostname
      ? window.location.hostname
      : "";
  if (hostname.startsWith(process.env.REACT_APP_LOCAL_ENV)) {
    console.log("INSIDE LOCAL");
    console.log("Local Signin:", LOCAL_SIGN_IN);
    console.log("Local Client Id:", LOCAL_CLIENT_ID);
    return awsLocalConfig;
  }

  if (hostname.startsWith(process.env.REACT_APP_DEV_ENV)) {
    console.log("INSIDE DEV");
    console.log("Dev Signin:", DEV_SIGN_IN);
    console.log("Dev Client Id:", DEV_CLIENT_ID);
    return awsDevConfig;
  }

  if (hostname.startsWith(process.env.REACT_APP_TEST_ENV)) {
    console.log("INSIDE TEST");
    console.log("Dev Signin:", TEST_SIGN_IN);
    console.log("Dev Client Id:", TEST_CLIENT_ID);
    return awsTestConfig;
  }

  if (hostname.startsWith(process.env.REACT_APP_PROD_ENV)) {
    console.log("INSIDE PROD");
    return awsProdConfig;
  }
}
