import DAN_PROFILE from "../assets/images/profile/Dan_Profile.jpg"
import RNelson from "../assets/images/profile/RNelson.png"
import JSchiro from "../assets/images/profile/JSchiro.png"
import Sking from "../assets/images/profile/Sking.png"
import RNorris from "../assets/images/profile/RNorris.png"
import Ben from "../assets/images/profile/Ben.png"

export const data = [
  {
    name: "Shawn Kingsberry",
    title: "VP Cyber IF",
    email: "shawn.k.kingsberry@saic.com",
    description:
      "Shawn Kingsberry is a vice president in the Digital Innovation Factory at SAIC. He leads teams focused on developing cyber solutions that protect our nation’s interests by enabling situational awareness, active cyber defense, and threat detection and remediation. Kingsberry has more than 30 years of experience in information technology and has a diverse background in federal and state government as well as corporate technology leadership. He has extensive experience leading large-scale secure digital transformation and fostering productive partnerships with internal teams and external technology partners. Prior to joining SAIC, Kingsberry led Global Advisory Services for Unisys and directed the development and deployment of secure cloud solutions for Engility. Prior to joining SAIC, Kingsberry led Global Advisory Services for Unisys and directed the development and deployment of secure cloud solutions for Engility.  Before moving to private industry, Kingsberry served as chief information officer for the federal Recovery Accountability and Transparency Board. In that role, he led successful efforts to provide transparency on stimulus spending and oversight of fraud, waste and abuse. He also oversaw the agency’s migration to the public cloud, the first federal-wide system to achieve that milestone. He is a recipient of the prestigious Fed 100 Award, recognizing his leadership at that agency. He also held executive positions with the U.S. Department of Agriculture. Kingsberry is frequent speaker and panelist who is recognized by senior industry and government leaders, peers and subordinates for his strategic mindset and leadership in the modernization of IT infrastructure to meet increasing security, data and citizen-services requirements.",
    image: Sking,
  },
  {
    name: "Dan Edwards",
    title: "Cybersecurity Ops Director",
    email: "daniel.l.edwards@saic.com",
    description:
      "Dan Edwards is the Director of Cybersecurity Operations in the Digital Innovation Factory – Cyber Operations. Dan joined SAIC in 2002 as manager of telecom voice systems engineering, where he lead the architecture, design, and engineering for SAIC’s IT outsourcing services to Entergy, a U.S. Fortune 500 power company. Prior to joining SAIC, Dan has held numerous positions of increasing authority leading telecom, network, and security engineering and operations teams at Sprint and Iridium for over 16 years. Lead technical team for the buildout of Sprints’ international network and served as the Director of Operations for Iridium Startup of Iridium North American Gateway first commercial offering of global mobile satellite services using a low earth orbit satellite network and hand held mobile phones. Dan holds professional certifications as a project management professional (PMP®), certified information.",
    image: DAN_PROFILE,
  },
  {
    name: "Joel Schiro",
    title: "Director of Cyber Demand Generation",
    email: "joel.t.schiro@saic.com",
    description:
      "Joel Schiro is the Director of Cyber Demand Generation in the Digital Innovation Factory – Cyber Operations. He is responsible for developing & managing the Cyber Operation’s capture and on-contract growth opportunity pipeline, as well as coordinating the assignment of the organization’s Cyber Subject Matter Experts to cyber opportunities. Joel joined SAIC in August 2010 as a Task Area Lead on the USCENTCOM J6 Contract, leading SAIC’s Cyber and Network Operations (NETOPS) efforts at the USCENTCOM J6 for seven years. After two successful re-competes of the USCENTOM J6 Contract, Joel transitioned to SAIC’s Cyber Practice Area, where he initially served as the Resource Director for all of SAIC’s Cyber employees and subsequently as the Cyber Practice Solution Delivery Director. Prior to joining SAIC, Joel completed a successful 20-year career as a Marine Corps Officer, retiring as a Lieutenant Colonel. He served three tours of duty in Iraq, including commanding a unit of 650 Marines during a 13-month tour of duty in Iraq. Initially upon retiring from the Marine Corps, Joel served as a contractor in Afghanistan, supporting the USCENTCOM J2 operations in that country. Joel earned his Bachelor Degree in Political Science from Marquette University in Milwaukee, WI and his Master of Science in Military Operations Art and Science from Air Command and Staff College in Montgomery, AL. He is currently certified in Certified Information Security Manager (CISM), CompTIA Security+, ITIL Foundations, and DevOps.",
    image: JSchiro,
  },
  {
    name: "Richard Nelson",
    title: "Cyber Engineer Director",
    email: "richard.f.nelson@saic.com",
    description:
      "Rich is currently a key member of the SAIC Cyber Innovation Factory where he concentrates on developing new technologies for SAIC and for key classified customers. He began his professional career in 1986 at the Naval Air Test Center in PAX River MD working on the E2-C aircraft in target detection & tracking as well as ECM & ECCM systems performance.  In 1996, Rich opened an office for a small company that eventually became a part of the Northrop Grumman Corporation.  There he supported the Naval Information Warfare Center (NIWC) customer as well as expanding into HALE UAVs, international military sales and even some work on the E2-D Advanced Hawkeye aircraft.  In 2008, Rich took a position with L-3 Communications as their Director of Engineering.  Most Recently, Richard has joined SAIC to take on many new challenges.  Such as the system design and analysis for the P-8a Poseidon MMA aircraft and the BAMS UAS. Rich currently holds both a Bachelor of Science in Electrical Engineering, a Master of Science in Software Engineering and a Master’s Certificate in Cyber Security.  He is an avid supporter of the engineering profession and the education community.  He has been involved in many activities serving as chairperson of the Joint Industry Advisory Board for the Citadel and the College of Charleston, board member for The Citadel school of Math and Computer Science, as well as a founding member and inaugural president of the Palmetto Roost Chapter of the Association of Old Crows.",
    image: RNorris,
  },
  {
    name: "Robert Morris",
    title: "Cyberspace Ops Director",
    email: "robert.a.morris@saic.com",
    description:
      "Colonel (ret) Robert A. “RAM” Morris, Director – Cyber Operations, SAIC, Leads SAIC’s Cyber Operations providing solution development and Customer Support for SAIC’s National Security, Defense and Federal/Civilian customers and is responsible for Cyberspace Operations Internal Research and Development programs. As Director, drives continuous improvement across the enterprise cyber workforce and associated expertise, empowers over 700 cyber professionals across all of the SAIC’s business areas in development and improvement of repeatable and differentiating cyber capabilities and executes the cyber business strategy.He retired as Director & Chief of Staff, Air Forces Cyber, responsible for operational AF Cyber issues with USCYBERCOM, NSA, OSD, ODNI, CIA and other NCR cyberspace partners. He commanded at the Group and Squadron level, including the AF’s only Cyber Warfare organization. He served as Deputy Director of Information Operations for the Under Secretary of Defense for Intelligence, NSA Global Capabilities Manager for IO, and AF special liaison officer to POTUS’ Comprehensive National Cyber-security Initiative. He serves on the SC Governor’s Cyber Executive Committee, Chairs SAIC’ Cyber Institute Leadership Council, Advisory Board Member for CSFI and Center for Nonprofit Information Technology Security, and was an Adjunct Professor of Cyber Intelligence for Utica College. He is a Master Navigator with 3,000+ hrs in the B-52G/H. Graduate of Clemson Univ, and MS in Space Operations, Univ of North Dakota. Awards And Decorations: SAIC CEO Award, Legion of Merit (OLC), Bronze Star, Defense Meritorious Service Medal (OLC), National Intelligence Meritorious Unit Citation, Intelligence Community Commendation, USAF Weapons School Outstanding Flying Award",
    image: RNelson,
  },
  {
    name: "Benjamin Mourad",
    title: "Cyber Solutions Architect Chief",
    email: "benjamin.e.mourad@saic.com",
    description:
      "Ben Mourad is chief architect of the Cyber team within the Digital Innovation Factory where he leads Cyber Innovation and Zero Trust strategy for our Federal, State, and Local clients. Ben Mourad joined SAIC in 2000 through the merger of Unisys Federal and SAIC. Throughout his career, he has held numerous positions leading innovation, strategy, and the development of cutting edge technology solutions on behalf of customers including AT&T, Verizon, Allegis Group, Citi, NASDAQ, McGraw Hill, Department of Defense, and Department of State. Ben earned his bachelor’s degree from the University of Pennsylvania in Philadelphia, PA and earned a masters in Engineering from George Washington University and an MBA from University of Maryland R.H. Smith School of Business.",
    image: Ben,
  }
]
