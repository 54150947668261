import { Typography } from "@material-ui/core"
import React from "react"
import AccordionComponent from "../components/accordion"

export default function faqContent(props) {
  return (
    <div className="faqContentContainer">
      <div className="titleLine" />
      <Typography className="category">Cyber Operations</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "cyberops") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                category={data.category}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

      <div className="titleLine" />
      <Typography className="category">CMSAT</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "cmsat") {
            return (
              <AccordionComponent
                question={data.question}
                answers={data.answers}
                category={data.category}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

      <div className="titleLine" />
      <Typography className="category">Architecture and Engineering</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "a&e") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                category={data.category}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

      <div className="titleLine" />
      <Typography className="category">Risk Management</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "riskman") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                answer2={data.answer2}
                answer3={data.answer3}
                answer4={data.answer4}
                answer5={data.answer5}
                category={data.category}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

      <div className="titleLine" />

      <Typography className="category">Global Cyber Intelligence</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "gci") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                category={data.category}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

      <div className="titleLine" />

      <Typography className="category">Cyber Situational Awaremess</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "csa") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                category={data.category}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

      <div className="titleLine" />

      <Typography className="category">CynoMLy</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "cyno") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                category={data.category}
              />
            );
          }
        })}
      </div>

      <div className="spacer" />

      <div className="titleLine" />

      <Typography className="category">Splunk Partnership</Typography>
      <div className="titleLine" />
      <div className="spacer" />
      <div className="accordionContainer">
        {props.content.map((data) => {
          if (data.category === "splunk") {
            return (
              <AccordionComponent
                question={data.question}
                answer={data.answer}
                category={data.category}
              />
            );
          }
        })}
      </div>
    </div>
  );
}
