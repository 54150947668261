import React from "react"
import { Typography, Button,Grid } from "@material-ui/core"
import offeringsData from "../data/offerings.json"

export default function OfferingsContent(props) {

  const [state] = React.useState({
    offeringsData: offeringsData,
  })

  return (
    <div className="offeringsContentContainer">

    <Grid container xs={12} >
      <Grid item xs={12} >
          <Button
                    href="https://issaic.saic.com/sites/digital/Cyber%20Documents/Forms/AllItems.aspx"
                    target="_blank"
                   
                    style={{fontSize: '18px', textAlign: 'center', width: '325px',height: '50px', padding: '10px'}}
                  >
                   
                    Document Repository
      
            </Button>

       </Grid>
          <div className="spacer" />
          <div className="dividerShape" />
         <div className="spacer" />
         
         {state.offeringsData.map((data) => {   
        return (
          <div>
          <Grid item xs={12} >
              <Typography>
              <a  href={data.link} target="_blank" alt={data.link}  >
                {data.title}
              </a>
              </Typography>
              <Typography>
                {data.desc}
              </Typography>
            </Grid>
      
          <div className="spacer" />

         </div>
        )
      })}
       <div className="spacer" />
       <div className="spacer" />

      </Grid>
 
      
    </div>
  )
}
