import axios from "axios"

const BASE_URL = process.env.REACT_APP_BASE_URL;

// Get Video demo data
const getVideoData = () => {
  return axios.get(`${BASE_URL}/getcybervideos`, { crossdomain: true });
};

// Post the view count of videos on demo page
const postVideoTrackerData = (videoId) => {
  console.log("Post Body", videoId);
  axios.post(BASE_URL + `/addview`, {
    key: videoId,
  });
};

export { getVideoData, postVideoTrackerData };
