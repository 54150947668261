import RISK_MGMT_IMG from "../assets/images/approach/RiskMgmt.jpg"
import ARCH_ENG_IMG from "../assets/images/approach/ArchEng.jpg"
import THREAT_MGMT_IMG from "../assets/images/approach/ThreatMgmt.jpg"
import MGMT_SEC_SERVICES_IMG from "../assets/images/approach/ManagedSecurityServices.jpg"
import CYBER_OPERATIONS_IMG from "../assets/images/approach/CyberspaceOperations.jpg"

export const data = [
  {
    title: "Risk Management",
    description:
      "We combine our unique risk management technology with exceptional expertise to efficiently identify and assess risk, prioritize and reduce cyber risk to an acceptable level. We enable our customers to create and use cyberspace resources to achieve mission results with resilience against continuously evolving adversarial threats and vulnerabilities.",
    image: RISK_MGMT_IMG,
    link: "",
    subLinkTitle: "",
    subLink: (
      <a
        href=""
        target="_blank"
      >
       
      </a>
    ),
    subLink2Title: "",
    subLink2: "",
    subLink3Title: "",
    subLink3: "",
  },
  {
    title: "Architecture and Engineering",
    description:
      "SAIC security architecture and engineering services help our customers plan and specify protection aligned with their mission objective, design and implement Zero Trust strategies to meet the challenge of an evolving cyber threat landscape.",
    image: ARCH_ENG_IMG,
    link: "",
    subLinkTitle: "",
    subLink: (
      <a
        href=""
        target="_blank"
      >
    
      </a>
    ),
    subLink2Title: <a></a>,
    subLink2: <a></a>,
    subLink3Title: <a></a>,
    subLink3: <a></a>
  },
  {
    title: "Threat Management",
    description:
      "Our integrated threat management service accurately detect advanced threats, enable mitigation of real threats before they cause and impact to operations. Utilizing coordinated collection of diverse threat data (structured, unstructured), our platform effectively transforms data to actionable intelligence, seamlessly integrate with core detection, orchestration and response solutions.",
    image: THREAT_MGMT_IMG,
    link: "",
    subLinkTitle: "",
    subLink: (
      <a
        href=""
        target="_blank"
      >
    
      </a>
    ),
    subLink2Title: <a></a>,
    subLink2: <a></a>,
    subLink3Title: <a></a>,
    subLink3: <a></a>
  },
  {
    title: "Managed  Security Services",
    description:
      "Adversaries work around-the-clock seeking opportunities to breach customer defenses. Our always on cybersecurity operations utilize advanced SOAR capabilities to speed-up accurate threat detection, improve response times. AI and ML provide actionable intelligence to our security operators to proactively hunt for internal and external threats",
    image: MGMT_SEC_SERVICES_IMG,
    link: "",
    subLinkTitle: "",
    subLink: (
      <a
        href=""
        target="_blank"
      >
    
      </a>
    ),
    subLink2Title: <a></a>,
    subLink2: <a></a>,
    subLink3Title: <a></a>,
    subLink3: <a></a>
  },
  {
    title: "Cyberspace Operations",
    description:
      "Our customers require technologies configured to meet their specific mission needs. We provide CISOs, Commanders and Operators visibility and control of their cyber ecosystem, CSA supported by cyber analytics and C2 processes, joint operational planning. CO support areas include OCO and DCO, systems analysis to identify /prioritize vulnerabilities, risk-reduction for protection and defense; and mission assurance.",
    image: CYBER_OPERATIONS_IMG,
    link: "",
    subLinkTitle: "",
    subLink: (
      <a
        href=""
        target="_blank"
      >
    
      </a>
    ),
    subLink2Title: <a></a>,
    subLink2: <a></a>,
    subLink3Title: <a></a>,
    subLink3: <a></a>
  },
  {
    title: "",
    description:
      "",
    image: '',
    link: "",
    subLinkTitle: "",
    subLink: (
      <a
        href=""
        target="_blank"
      >
    
      </a>
    ),
    subLink2Title: <a></a>,
    subLink2: <a></a>,
    subLink3Title: <a></a>,
    subLink3: <a></a>
  }


]

