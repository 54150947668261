import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import {
  List,
  ListItemText,
  ListItemAvatar,
  ListItem,
  Avatar,
  Link,
} from "@material-ui/core/"
import axios from "axios"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    backgroundColor: "transparent",
    fontSize: "9px",
  },
  inline: {
    display: "inline",
  },
}));

export default function ListComponent(props) {
  return (
    <List>
      <ListItem>
        <ListItemText
          primary={
            <b style={{ fontSize: "20px", color: "#006bb6" }}>{props.title}</b>
          }
          secondary={
            <span style={{ color: "#3b484f", fontSize: "15px" }}>
              {props.desc}
            </span>
          }
        />
      </ListItem>
    </List>
  );
}

function ListIconComponent(props) {
  return (
    <List className="ListIconContainer">
      <ListItem>
        <ListItemAvatar>{props.icon}</ListItemAvatar>
        <ListItemText
          primary={
            props.email === "true" ? (
              <span>
                <b>
                  <a href={"mailto:" + props.text}>{props.text}</a>
                </b>
              </span>
            ) : (
              <span>
                <b>{props.text}</b>
              </span>
            )
          }
        />
      </ListItem>
    </List>
  );
}

function ListMediaComponent(props) {
  useEffect(() => {
    retrieveData();
  });

  const [state, setState] = React.useState({
    dataValues: [],
  });

  const getAllData = () => {
    return axios.get(
      "https://1tzahn8pmc.execute-api.us-east-1.amazonaws.com/prod/getvideos",
      { crossdomain: true }
    );
  };

  const retrieveData = () => {
    getAllData()
      .then((response) => {
        setState({
          dataValues: response.data.body,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      {state.dataValues.map((data) => {
        return (
          <List>
            <Link href={`/demos/${data.Title}`} style={{ color: "#006bb6" }}>
              <ListItem>
                <ListItemAvatar>
                  <video style={{ width: "125px", height: "100px" }}>
                    {" "}
                    <source src={data.Source} type="video/mp4" /> Video Not
                    Playing
                  </video>
                </ListItemAvatar>
                <ListItemText
                  primary={data.Title}
                  secondary={<span>{data.Group}</span>}
                />
              </ListItem>
            </Link>
          </List>
        );
      })}
    </div>
  );
}

function ListContentComponent(props) {
  useEffect(() => {
    retrieveData();
  });

  const [state, setState] = React.useState({
    dataValues: [],
  });

  const getAllData = () => {
    return axios.get(
      "https://1tzahn8pmc.execute-api.us-east-1.amazonaws.com/prod/getvideos",
      { crossdomain: true }
    );
  };

  const retrieveData = () => {
    getAllData()
      .then((response) => {
        setState({
          dataValues: response.data.body,
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const retrieveContent = () => {
    const contentFormat = (type, data) => {
      if (type === "Latest") {
        return (
          <div>
            <b>Challenges</b>
            <br />
            {data.Challenges}
            <br />
            <br />
            <b>Customer Needs</b>
            <br />
            {data.CustomerNeed}
            <br />
            <br />
            <b>Solution</b>
            <br />
            {data.Solution}
          </div>
        );
      }
    };
    return state.dataValues.map((data) => {
      if (props.type === data.Title) {
        return (
          <ListItemText
            primary={
              <span style={{ fontSize: "14px" }}>
                <b>Category: </b>
                {data.Group}
              </span>
            }
            secondary={
              <p style={{ width: "95%" }}>
                {data.Group === "Latest"
                  ? contentFormat(data.Group, data)
                  : data.Description}
              </p>
            }
            style={{ paddingLeft: "10px" }}
          />
        );
      }
    });
  };

  return (
    <div>
      <List>
        <ListItem>{retrieveContent()}</ListItem>
      </List>
    </div>
  );
}

export {
  ListComponent,
  ListMediaComponent,
  ListContentComponent,
  ListIconComponent,
};
